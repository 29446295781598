import React from 'react'
import {
  DateField as AriaDateField,
  DateFieldProps as AriaDateFieldProps,
  DateInput as AriaDateInput,
  DateInputProps,
  DateSegment,
  DateValue,
  ValidationResult
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { Description, FieldError, Label } from './Field'
import { composeTailwindRenderProps, groupBaseStyles } from './utils'
import { twMerge } from 'tailwind-merge'

export interface DateFieldProps<T extends DateValue>
  extends AriaDateFieldProps<T> {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
}

function DateField<T extends DateValue>({
  description,
  label,
  errorMessage,
  ...props
}: DateFieldProps<T>) {
  return (
    <AriaDateField
      shouldForceLeadingZeros
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'flex flex-col gap-1 text-left'
      )}>
      {label && <Label className={'capitalize-first-letter'}>{label}</Label>}
      <DateInput className={twMerge(groupBaseStyles(), 'p-1')} />
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
    </AriaDateField>
  )
}

const segmentStyles = tv({
  base: 'rounded p-1 outline outline-0 caret-transparent text-neutral-800',
  variants: {
    isPlaceholder: {
      true: 'text-neutral-600 italic'
    },
    isDisabled: {
      true: 'text-neutral-200'
    },
    isFocused: {
      true: 'bg-brand-600 text-neutral-0'
    }
  }
})

export function DateInput(props: Omit<DateInputProps, 'children'>) {
  return (
    <AriaDateInput {...props}>
      {segment => <DateSegment segment={segment} className={segmentStyles} />}
    </AriaDateInput>
  )
}

export default DateField
